import React, { Component } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'

export class Footer extends Component {
    render() {
        return (
            <div>
                <footer className="footer">
                <Container>
                    <Row className="footer-row">
                        <Col className="footer-col footer-first-col" xs={12} sm={6} md={6} lg={3}>
                            <h4 className="footer-item-title">Wichtige Seiten</h4>
                            <div className="footer-item-wrapper"><Link to="/mannschaft" className="footer-item">Mannschaft</Link></div>
                            <div className="footer-item-wrapper"><Link to="/management" className="footer-item">Management</Link></div>
                            {/* <div className="footer-item-wrapper"><Link to="/ergebnisse-tus-kriftel" className="footer-item">Ergebnisse</Link></div> */}
                            {/* <div className="footer-item-wrapper"><Link to="/spielplan-heimspiele" className="footer-item">Heimspiele</Link></div> */}
                        </Col>
                        <Col className="footer-col footer-second-col" xs={12} sm={6} md={6} lg={4}>
                            <div className="footer-item-wrapper"><Link to="/spielplan-tus-kriftel" className="footer-item">Spielplan TuS Kriftel</Link></div>
                            <div className="footer-item-wrapper"><Link to="/pressekontakt" className="footer-item">Pressekontakt</Link></div>
                            <div className="footer-item-wrapper"><Link to="/sponsoren" className="footer-item">Sponsoren</Link></div>
                            {/* <div className="footer-item-wrapper"><Link to="/downloads" className="footer-item">Downloads</Link></div> */}
                            <div className="footer-item-wrapper"><Link to="/impressum" className="footer-item">Impressum</Link></div>
                            <div className="footer-item-wrapper"><Link to="/datenschutz" className="footer-item">Datenschutz</Link></div>
                        </Col>
                        <Col className="footer-col footer-info-col footer-item-title2" xs={12} md={7} lg={5}>
                        <h4 className="">TuS Kriftel 1884 e.V.</h4>
                        <p style={{fontSize: '14px'}}>Willkommen auf der offiziellen Homepage der Turn- und Sportgemeinschaft Kriftel 1884 e.V. - Volleyball in der 2. Bundesliga.</p>
                        </Col>
                    </Row>
                </Container>
            </footer>
            {/* <div class="copyright-footer">
                <Container>
                    <Row className="footer-copyright-row">
                        <Col>
                            <div className="copyright">
                                <a rel="noopener noreferrer" target="_blank" style={{color: 'white'}} href="https://flippink.de">© Created by Flippink E-Commerce Solutions UG</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div> */}
            </div>
        )
    }
}

export default Footer
