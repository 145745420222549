import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { BrowserRouter, Route, Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Navigation from './components/navigation/Navigation';
import Home from './components/pages/Home';
import Posts from './components/pages/Posts';
import Team from './components/pages/Team';
import Sponsors from './components/pages/Sponsors';
import Supporters from './components/pages/Supporters';
import Management from './components/pages/Management';
import SpielplanKriftel from './components/pages/SpielplanKriftel';
import ErgebnisseKriftel from './components/pages/ErgebnisseKriftel';
import ErgebnisseHeimspiele from './components/pages/ErgebnisseHeimspiele';
import SpielplanMS from './components/pages/SpielplanMS';
import SpielplanMN from './components/pages/SpielplanMN';
import ErgebnisseMN from './components/pages/ErgebnisseMN';
import ErgebnisseMS from './components/pages/ErgebnisseMS';
import SpielplanHeimspiele from './components/pages/SpielplanHeimspiele';
import Downloads from './components/pages/Downloads';
import Impressum from './components/pages/Impressum';
import Datenschutz from './components/pages/Datenschutz';
import Press from './components/pages/Press';
import Anfahrt from './components/pages/Anfahrt';
import News from './components/pages/News';
import ManagementSingle from './components/pages/ManagementSingle';
import Trainings from './components/pages/Trainings';
import Tickets from './components/pages/Tickets';
import Akkreditierung from './components/pages/Akkreditierung';
import Newsletter from './components/pages/Newsletter';
import PostsSingle from './components/pages/PostsSingle';
import PlayerSingle from './components/pages/PlayerSingle';
import Footer from './components/footer/Footer'
import Mannschaft from './components/pages/Mannschaft'
import Mannschaften from './components/pages/Mannschaften'
import CookieConsent from "react-cookie-consent";

export class App extends Component {
  render(){

    return (
      <BrowserRouter>
  
        <Navigation />
  
        <Container>
              <Route path="/" exact component={Home} ></Route>
              <Route path="/mannschaft" exact component={Team} ></Route>
              <Route path="/tickets" exact component={Tickets} ></Route>
              <Route path="/sponsoren" exact component={Sponsors} ></Route>
              <Route path="/pressekontakt" exact component={Press} ></Route>
              <Route path="/downloads" exact component={Downloads} ></Route>
              <Route path="/akkreditierung" exact component={Akkreditierung} ></Route>
              <Route path="/newsletter" exact component={Newsletter} ></Route>
              <Route path="/spielplan-anfahrt-halle" exact component={Anfahrt} ></Route>
              <Route path="/anfahrt-halle" exact component={Anfahrt} ></Route>
              <Route path="/anfahrt" exact component={Anfahrt} ></Route>
              <Route path="/ergebnisse-maenner-sued" exact component={ErgebnisseMS} ></Route>
              <Route path="/ergebnisse-maenner-nord" exact component={ErgebnisseMN} ></Route>
              <Route path="/spielplan-maenner-sued" exact component={SpielplanMS} ></Route>
              <Route path="/spielplan-maenner-nord" exact component={SpielplanMN} ></Route>
              <Route path="/spielplan-tus-kriftel" exact component={SpielplanKriftel} ></Route>
              <Route path="/ergebnisse-tus-kriftel" exact component={ErgebnisseKriftel} ></Route>
              <Route path="/ergebnisse-heimspiele" exact component={ErgebnisseHeimspiele} ></Route>
              <Route path="/spielplan-kriftel" exact component={SpielplanKriftel} ></Route>
              <Route path="/heimspiele" exact component={SpielplanHeimspiele} ></Route>
              <Route path="/spielplan-heimspiele" exact component={SpielplanHeimspiele} ></Route>
              <Route path="/foerderer" exact component={Supporters} ></Route>
              <Route path="/trainingszeiten" exact component={Trainings} ></Route>
              <Route path="/berichte" exact component={Posts} ></Route>
              <Route path="/impressum" exact component={Impressum} ></Route>
              <Route path="/datenschutz" exact component={Datenschutz} ></Route>
              <Route path="/medien-news" exact component={News} ></Route>
              <Route path={"/posts/:id"} exact component={PostsSingle} ></Route>
              <Route path="/spieler" exact component={Team} ></Route>
              <Route path={"/spieler/:name"} exact component={PlayerSingle} ></Route>
              <Route path="/management" exact component={Management} ></Route>
              <Route path={"/management/:name"} exact component={ManagementSingle} ></Route>
              <Route path={"/mannschaften"} exact component={Mannschaften} ></Route>
              <Route path={"/mannschaft/:id"} exact component={Mannschaft} ></Route>
        </Container>

        <Footer />

        <CookieConsent
          location="bottom"
          buttonText="Akzeptieren"
          cookieName="tuskriftel-datenschutz"
          style={{ background: "#e30613" }}
          buttonStyle={{ backgroundColor: '#bd0511', color: "#fff", fontSize: "16px" }}
          expires={150}
      >
          Mit der Nutzung dieser Website erklären Sie sich damit einverstanden, dass wir Cookies verwenden.{" "}
          <span style={{ fontSize: "14px", fontWeight: "600", marginLeft: "15px" }}>
            <Link to="/datenschutz" className="footer-item">Datenschutzerklärung</Link>
          </span>
      </CookieConsent>
  
      </BrowserRouter>
    );
  }
  }

export default App;
