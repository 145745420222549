import React, { Component } from 'react';
import NavigationCollapse from './NavigationCollapse';
import NavigationBrand from './NavigationBrand';
import { Navbar, Container } from 'react-bootstrap';

export class Navigation extends Component {
    render() {
        return (
            <div>
            <Navbar variant="dark" style={navbarStyle} expand="lg">

                <Container>
                    <NavigationBrand />

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />

                    <NavigationCollapse />
                </Container>

            </Navbar>
            </div>
        )
    }
}

const navbarStyle = {
    background: "#e30613"
}

export default Navigation
