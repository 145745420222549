import React, { Component } from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
// import ReactLoading from 'react-loading'


export class NavigationCollapse extends Component {

    state = {
        loading: true,
        teams: null
    };

    // async componentDidMount(){
    //     const apiurl = `https://api-tus.whitelabel-hosting.de/api/collections/get/mannschaften`;
    //     const response = await fetch(apiurl);
    //     const data = await response.json();
    //     const teams = data.entries.sort((a, b) => a.reihenfolge - b.reihenfolge)
    //     this.setState({ teams, loading: false });
    // }

    render() {

        // if(this.state.loading) {return <div className="d-flex justify-content-center"><ReactLoading className="" type="bubbles" color="#e30613" height={'5%'} width={'5%'} /></div>}

        // const teamItems = this.state.teams.map((team, key) =>
        //     <Link to={'/mannschaft/' + team._id} className="dropdown-item">{ team.title }</Link>
        // );

        return (
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">

                {/* <NavDropdown title="Mannschaften" id="basic-nav-dropdown">
                    { teamItems }
                </NavDropdown> */}

                <NavDropdown title="Team" id="basic-nav-dropdown">
                    <Link to="/berichte" className="dropdown-item">Berichte</Link>
                    <Link to="/mannschaft" className="dropdown-item">Mannschaft</Link>
                    <Link to="/management" className="dropdown-item">Management</Link>
                    <Link to="/trainingszeiten" className="dropdown-item">Trainingszeiten</Link>
                </NavDropdown>

                <NavDropdown title="Spielplan" id="basic-nav-dropdown">
                    <Link to="/spielplan-tus-kriftel" className="dropdown-item">TuS Kriftel</Link>
                    {/* <Link to="/spielplan-heimspiele" className="dropdown-item">Heimspiele</Link> */}
                    <Link to="/spielplan-maenner-sued" className="dropdown-item">Männer Süd</Link>
                    <Link to="/spielplan-maenner-nord" className="dropdown-item">Männer Nord</Link>
                    <Link to="/spielplan-anfahrt-halle" className="dropdown-item">Anfahrt Halle</Link>
                </NavDropdown>

                {/* <NavDropdown title="Ergebnisse" id="basic-nav-dropdown">
                    <Link to="/ergebnisse-tus-kriftel" className="dropdown-item">TuS Kriftel</Link>
                    <Link to="/ergebnisse-heimspiele" className="dropdown-item">Heimspiele</Link>
                    <Link to="/ergebnisse-maenner-sued" className="dropdown-item">Männer Süd</Link>
                    <Link to="/ergebnisse-maenner-nord" className="dropdown-item">Männer Nord</Link>
                </NavDropdown> */}

                <Link className="nav-link" to="/tickets">Tickets</Link>

                {/* <NavDropdown title="Fanclub" id="basic-nav-dropdown">
                   <Link to="/galerie" className="dropdown-item">Gallerie</Link>
                   <Link to="/newsletter" className="dropdown-item">Newsletter</Link>
                </NavDropdown> */}

                <NavDropdown title="Partner" id="basic-nav-dropdown">
                    <Link to="/sponsoren" className="dropdown-item">Sponsoren</Link>
                    <Link to="/foerderer" className="dropdown-item">Förderer</Link>
                    {/* <Link to="/verbaende" className="dropdown-item">Verbände</Link> */}
                </NavDropdown>

                <Link className="nav-link" to="/mannschaften">Mannschaften</Link>

                <NavDropdown title="Medien" id="basic-nav-dropdown">
                    <Link to="/berichte" className="dropdown-item">Berichte</Link>
                    {/* <Link to="/downloads" className="dropdown-item">Downloads</Link> */}
                    <Link to="/pressekontakt" className="dropdown-item">Pressekontakt</Link>
                    {/* <Link to="/akkreditierung" className="dropdown-item">Akkreditierung</Link> */}
                </NavDropdown>

                <a href="https://sportdeutschland.tv/vbl" rel="noopener noreferrer" target="_blank" className="nav-link">VBL.TV</a>

                </Nav>
            </Navbar.Collapse>
        )
    }
}

export default NavigationCollapse
