import React, { Component } from 'react'
import { Row, Col } from 'react-bootstrap';
import Sidebar from '../sidebar/Sidebar'
import MetaTags from 'react-meta-tags'

export class ErgebnisseMN extends Component {
    render() {
        return (
            <Row className="mt-4">
                <Col xs={{span: 12, order:12}} sm={{span: 5, order:1}} md={4} lg={3} xl={3}>
                <Sidebar />
                </Col>
                <Col xs={{span: 12, order: 1}} sm={{span: 7, order:12}} md={8} lg={9} xl={9} className="mb-4">
                    <MetaTags>
                        <title>Ergebnisse Männer Nord | TuS Kriftel Volleyball 2. BL</title>
                        <meta name="description" content="Ergebnisse Männer Nord - TuS Kriftel Volleyball 2. BL" />
                        <meta property="og:title" content="Ergebnisse Männer Nord - TuS Kriftel Volleyball 2. BL" />
                        <meta property="og:image" content="https://api-tus.whitelabel-hosting.de/storage/uploads/2020/02/19/5e4dc6ea91753tuskriftel-main-og.png" />
                    </MetaTags>
                    <Row>
                        <Col xs="12">
                            <iframe title="ergebnisse-maenner-nord" style={{width: '100%', borderRadius: '3px'}} frameborder="0" width="1400px" height="1645px;" src="https://www.volleyball-bundesliga.de/cms/home/2blnm/2blnm_scoresstats/2blnm_tabelle/2blnm_tabelle.xhtml"></iframe>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default ErgebnisseMN
