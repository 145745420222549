import React, { Component } from 'react'
import { Row, Col, Card, Image } from 'react-bootstrap'
import Sidebar from '../sidebar/Sidebar'
import ReactLoading from 'react-loading'
import MetaTags from 'react-meta-tags'

export class Press extends Component {

    state = {
        loading: true,
        contacts: null
    };

    async componentDidMount(){
        const apiurl = "https://api-tus.whitelabel-hosting.de/api/collections/get/management?filter[role]=Pressewart";
        const response = await fetch(apiurl);
        const data = await response.json();
        this.setState({ contacts: data.entries, loading: false });
    }

    render() {

        if(this.state.loading) {return <div className="d-flex justify-content-center"><ReactLoading className="" type="bubbles" color="#e30613" height={'5%'} width={'5%'} /></div>}

        // if(!this.state.posts) {return <div>no content recieved...</div>}

        const contacts = this.state.contacts.map((contact, key) =>
            <Col key={contact._id} xs={12} lg={6} className="mb-4"> 
                <Card clasName="card">
                    <Row>
                        <Col xs={6}>
                            <Image fluid src={contact.thumbnail_url} />
                        </Col>
                        <Col className="management-view-info" xs={6}>
                            <div>
                                <div className="management-view-name">{contact.firstname + " " + contact.lastname}</div>
                                <div className="management-view-role">{contact.role}</div>
                            </div>
                            <div className="mt-2">
                                <div><a href={"mailto:" + contact.email} className="management-view-role">{contact.email}</a></div>
                                <div><a href={"tel:" + contact.phone} className="management-view-role">{contact.phone}</a></div>
                            </div>
                        </Col>
                    </Row>
                </Card>
            </Col>
        );

        return (
            <Row className="mt-4">
                <Col xs={{span: 12, order:12}} sm={{span: 5, order:1}} md={4} lg={3} xl={3}>
                <Sidebar />
                </Col>
                <Col xs={{span: 12, order: 1}} sm={{span: 7, order:12}} md={8} lg={9} xl={9}>
                    <MetaTags>
                        <title>Pressekontakt | TuS Kriftel Volleyball 2. BL</title>
                        <meta name="description" content="Pressekontakt der TuS Kriftel Volleyball 2. Bundesliga" />
                        <meta property="og:title" content="Pressekontakt der TuS Kriftel Volleyball 2. BL" />
                        <meta property="og:image" content="https://api-tus.whitelabel-hosting.de/storage/uploads/2020/02/19/5e4dc6ea91753tuskriftel-main-og.png" />
                    </MetaTags>
                    <Row>
                    {contacts}
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default Press
