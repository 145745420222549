import React, { Component } from 'react'
import ReactLoading from 'react-loading'
import { Row, Col, Card } from 'react-bootstrap'
import Sidebar from '../sidebar/Sidebar'
import MetaTags from 'react-meta-tags'

export class Supporters extends Component {

    state = {
        loading: true,
        content: null
    };

    async componentDidMount(){
        const apiurl = `https://api-tus.whitelabel-hosting.de/api/singletons/get/foerderer_page`;
        const response = await fetch(apiurl);
        const data = await response.json();
        this.setState({ content: data, loading: false });
    }

    render() {

        if(this.state.loading) {return <div className="d-flex justify-content-center"><ReactLoading className="" type="bubbles" color="#e30613" height={'5%'} width={'5%'} /></div>}

        if(!this.state.content) {return <div>no content recieved...</div>}

        return (
            <Row className="mt-4">
                <Col xs={{span: 12, order:12}} sm={{span: 5, order:1}} md={4} lg={3} xl={3}>
                <Sidebar />
                </Col>
                <Col xs={{span: 12, order: 1}} sm={{span: 7, order:12}} md={8} lg={9} xl={9} className="mb-4">
                    <MetaTags>
                        <title>Förderer | TuS Kriftel Volleyball 2. BL</title>
                        <meta name="description" content="Förderer - TuS Kriftel Volleyball 2. Bundesliga" />
                        <meta property="og:title" content="Förderer - TuS Kriftel Volleyball 2. BL" />
                        <meta property="og:image" content="https://api-tus.whitelabel-hosting.de/storage/uploads/2020/02/19/5e4dc6ea91753tuskriftel-main-og.png" />
                    </MetaTags>
                    <Row>
                    <Col xs={12}> 
                            <Card clasName="card">
                                    <Card.Body>
                                        <div className="singleton-content"  dangerouslySetInnerHTML={{__html: this.state.content.content}}></div>
                                    </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default Supporters
