import React, { Component } from 'react'
import { Row, Col, Image, Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ReactLoading from 'react-loading'

export class TeamHeroContent extends Component {

    state = {
        loading: true,
        players: null
    };

    async componentDidMount(){
        const apiurl = "https://api-tus.whitelabel-hosting.de/api/collections/get/player?sort[order]=1";
        const response = await fetch(apiurl);
        const data = await response.json();
        this.setState({ players: data.entries, loading: false });
    }

    render() {

        if(this.state.loading) {return <div className="d-flex justify-content-center"><ReactLoading className="" type="bubbles" color="#e30613" height={'5%'} width={'5%'} /></div>}

        const players = this.state.players.map((player, key) =>
            <Col className="mb-4" key={player._id} xs={6} sm={6} md={6} lg={4} xl={3}>
                <Link to={ "/spieler/" + player.firstname.toLowerCase() + "-" + player.lastname.toLowerCase() } className="single-post-link">
                    <Card>
                        <div>
                            <Image className="img-fluid" src={player.thumbnail_url} rounded />
                            <div className="player-content">
                                <div className="player-title">{player.firstname + " " + player.lastname}</div>
                                <div className="player-role">{player.role}</div>
                            </div>
                        </div>
                    </Card>
                </Link>
            </Col>
        );

        return (
            <Row>
                {players}
            </Row>
        )
    }
}

export default TeamHeroContent
