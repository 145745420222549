import React, { Component } from 'react'
import CardTeam from '../cards/CardTeam'
import { Row, Col } from 'react-bootstrap'
import ReactLoading from 'react-loading'
import Sidebar from '../sidebar/Sidebar'
import MetaTags from 'react-meta-tags'

export class Mannschaften extends Component {

    state = {
        loading: true,
        teams: null
    };

    async componentDidMount(){
        const apiurl = `https://api-tus.whitelabel-hosting.de/api/collections/get/mannschaften`;
        const response = await fetch(apiurl);
        const data = await response.json();
        const teams = data.entries.sort((a, b) => a.reihenfolge - b.reihenfolge)
        this.setState({ teams, loading: false });
    }
    
    render() {

        function trimTitle(title) {
            return title.slice(0, 45)
        }

        if(this.state.loading) {return <div className="d-flex justify-content-center"><ReactLoading className="" type="bubbles" color="#e30613" height={'5%'} width={'5%'} /></div>}

        // if(!this.state.posts) {return <div>no content recieved...</div>}

        const teams = this.state.teams.map((team, key) =>
            <Col key={team._id} xs={12} sm={12} md={12} lg={6} xl={6} className="mb-4">
                <CardTeam link={team._id} title={trimTitle(team.title)} img={'https://api-tus.whitelabel-hosting.de/' + team.bild.path}/>
            </Col>
        );

        return (
            <Row className="mt-4">
                <Col xs={{span: 12, order:12}} sm={{span: 5, order:1}} md={4} lg={3} xl={3}>
                <Sidebar />
                </Col>
                <MetaTags>
                        <title>Mannschaften | TuS Kriftel Volleyball 2. BL</title>
                        <meta name="description" content="Mannschaften der TuS Kriftel Volleyball 2. Bundesliga" />
                        <meta property="og:title" content="Mannschaften der TuS Kriftel Volleyball 2. BL" />
                        <meta property="og:image" content="https://api-tus.whitelabel-hosting.de/storage/uploads/2020/02/19/5e4dc6ea91753tuskriftel-main-og.png" />
                </MetaTags>
                <Col xs={{span: 12, order: 1}} sm={{span: 7, order:12}} md={8} lg={9} xl={9}>
                    <Row>
                        {teams}
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default Mannschaften
