import React, { Component } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import ReactLoading from 'react-loading'
import Sidebar from '../sidebar/Sidebar'
import MetaTags from 'react-meta-tags'

export class SpielplanKriftel extends Component {

    state = {
        loading: true,
        matches: null
    };

    async componentDidMount(){
        const apiurl = "https://api-tus.whitelabel-hosting.de/api/collections/get/match?sort[date_sortable]=1&limit=500&filter[$or][0][team_1]=TuS+Kriftel&filter[$or][1][team_2]=TuS+Kriftel&filter[result][$lt]=0";
        const response = await fetch(apiurl);
        const data = await response.json();
        this.setState({ matches: data.entries, loading: false });
    }
    
    render() {
        return (
            <Row className="mt-4">
                <Col xs={{span: 12, order:12}} sm={{span: 5, order:1}} md={4} lg={3} xl={3}>
                <Sidebar />
                </Col>
                <Col xs={{span: 12, order: 1}} sm={{span: 7, order:12}} md={8} lg={9} xl={9}>
                    <MetaTags>
                        <title>Spielplan | TuS Kriftel Volleyball 2. BL</title>
                        <meta name="description" content="Spielplan - TuS Kriftel Volleyball 2. Bundesliga" />
                        <meta property="og:title" content="Spielplan - TuS Kriftel Volleyball 2. BL" />
                        <meta property="og:image" content="https://api-tus.whitelabel-hosting.de/storage/uploads/2020/02/19/5e4dc6ea91753tuskriftel-main-og.png" />
                    </MetaTags>
                    <Row>
                        <Col xs="12">
                        <iframe title="spielplan-maenner-sued" style={{width: '100%', borderRadius: '3px'}} frameborder="0" width="1400px" height="1645px;" src="https://www.volleyball-bundesliga.de/cms/home/2_bundesliga_maenner/2_bundesliga_maenner_sued/mannschaften.xhtml?226159.teamId=758958502&226159.view=matches#samsCmsComponent_226159"></iframe>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default SpielplanKriftel
