import React, { Component } from 'react';
import { Navbar, Image } from 'react-bootstrap';
import Logo from '../../media/Logo.svg';
import { Link } from 'react-router-dom';

export class NavigationBrand extends Component {

    render() {
        return (
            <Navbar.Brand href="#home">
                <Link to="/">
                    <Image id="logo" src={Logo} rounded />
                </ Link>
            </Navbar.Brand>
        )
    }
}

export default NavigationBrand
