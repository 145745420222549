import React, { Component } from 'react'
import { Card } from 'react-bootstrap';

export class SidebarAktionscontainer extends Component {
    render() {
        return (
            <Card className="sidebar-card">
                <a target="_blank" rel="noopener noreferrer" href="https://sportdeutschland.tv/2-volleyball-bundesliga">
                    <Card.Body style={cardBodyStyle} className="text-center">
                        <img alt="Aktionscontainer" style={imageStyle} className="img-fluid" src="https://api-tus.whitelabel-hosting.de/storage/uploads/2023/08/16/WhatsApp-Image-2023-08-02-at-15.32.00_uid_64dd02b21ec3e.jpeg"></img>
                    </Card.Body>
                </a>
            </Card>
        )
    }
}

const cardBodyStyle = {
    padding: '0px'
}

const imageStyle = {
    borderRadius: '3px'
}

export default SidebarAktionscontainer
