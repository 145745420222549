import React, { Component } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import Sidebar from '../sidebar/Sidebar'
import ReactLoading from 'react-loading'
import MetaTags from 'react-meta-tags'

export class Anfahrt extends Component {

    state = {
        loading: true,
        content: null
    };

    async componentDidMount(){
        const apiurl = `https://api-tus.whitelabel-hosting.de/api/singletons/get/anfahrt_page`;
        const response = await fetch(apiurl);
        const data = await response.json();
        this.setState({ content: data, loading: false });
    }

    render() {

        if(this.state.loading) {return <div className="d-flex justify-content-center"><ReactLoading className="" type="bubbles" color="#e30613" height={'5%'} width={'5%'} /></div>}

        if(!this.state.content) {return <div>no content recieved...</div>}

        return (

            <Row className="my-4">
                
                <Col xs={{span: 12, order:12}} sm={{span: 5, order:1}} md={4} lg={3} xl={3}>
                    <Sidebar />
                </Col>

                <Col className="mb-4" xs={{span: 12, order: 1}} sm={{span: 6, order:12}} md={8} lg={9} xl={9}>
                    <MetaTags>
                        <title>Anfahrt zur Halle | TuS Kriftel Volleyball 2. BL</title>
                        <meta name="description" content="Anfahrt zur Halle für die Heimspiele der TuS Kriftel" />
                        <meta property="og:title" content="Anfahrt zur Halle der TuS Kriftel" />
                        <meta property="og:image" content="https://api-tus.whitelabel-hosting.de/storage/uploads/2020/02/19/5e4dc6ea91753tuskriftel-main-og.png" />
                    </MetaTags>
                    <Card>
                        <Row>

                            <Col xs={{span: 12, order: 1}} sm={12} md={12} lg={{span: 7, order: 12}} xl={7}>
                            <iframe title="anfahrt-map" style={{width: '100%', height: '100%', borderRadius: '3px'}} frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=Weingartenhalle%0ARossertstra%C3%9Fe+2%2C+65830+Kriftel&amp;key=AIzaSyD09zQ9PNDNNy9TadMuzRV_UsPUoWKntt8"></iframe>
                            </Col>

                            <Col xs={{span: 12, order: 12}} sm={12} md={12} lg={{span: 5, order: 1}} xl={5} className="d-flex align-items-center">
                                <div className="anfahrt-text-wrapper">
                                    <div className="singleton-content"  dangerouslySetInnerHTML={{__html: this.state.content.content}}></div>
                                </div>
                            </Col>

                        </Row>
                    </Card>
                </Col>

            </Row>

        )
    }
}

export default Anfahrt
