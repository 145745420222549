import React, { Component } from 'react'
import { Card } from 'react-bootstrap';

export class SidebarFacebookpage extends Component {
    render() {
        return (

            <Card className="sidebar-card">
                <a target="_blank" rel="noopener noreferrer" href="https://www.volleyball-bundesliga.de/2blsmaktion">
                    <Card.Body style={cardBodyStyle} className="text-center">
                    <iframe title="facebook-page-embeed" className="d-block d-sm-none d-md-none d-lg-none d-xl-none" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FKriftelVolley01&tabs=timeline&width=300&height=1000&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="300" height="1000" style={iframeStyle} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                    <iframe title="facebook-page-embeed" className="d-none d-sm-block d-md-none d-lg-none d-xl-none" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FKriftelVolley01&tabs=timeline&width=240&height=1000&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="240" height="1000" style={iframeStyle} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                    <iframe title="facebook-page-embeed" className="d-none d-sm-none d-md-block d-lg-none d-xl-none" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FKriftelVolley01&tabs=timeline&width=210&height=1000&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="210" height="1000" style={iframeStyle} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                    <iframe title="facebook-page-embeed" className="d-none d-sm-none d-md-none d-lg-block d-xl-none" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FKriftelVolley01&tabs=timeline&width=210&height=970&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="210" height="970" style={iframeStyle} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                    <iframe title="facebook-page-embeed" className="d-none d-sm-none d-md-none d-lg-none d-xl-block" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FKriftelVolley01&tabs=timeline&width=255&height=1005&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId" width="255" height="1005" style={iframeStyle} scrolling="no" frameborder="0" allowTransparency="true" allow="encrypted-media"></iframe>
                    </Card.Body>
                </a>
            </Card>

        )
    }
}

const iframeStyle = {
    border: 'none',
    overflow: 'hidden',
    borderRadius: '3px'
}

const cardBodyStyle = {
    padding: '0px',
    display: 'flex',
    justifyContent: 'center'
}

export default SidebarFacebookpage
