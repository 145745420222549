import React, { Component } from 'react'
import ReactLoading from 'react-loading'
import { Row, Col, Card, Image } from 'react-bootstrap'
import Sidebar from '../sidebar/Sidebar'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faTwitter, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import MetaTags from 'react-meta-tags'

export class PlayerSingle extends Component {

    state = {
        loading: true,
        player: null
    };
    
    async componentDidMount(){
        const name = this.props.match.params.name.replace("-", " ");
        const firstname = name.split(' ').slice(0, -1).join(' ');
        const lastname = name.split(' ').slice(-1).join(' ');
        const Firstname = firstname.charAt(0).toUpperCase() + firstname.slice(1); 
        const Lastname = lastname.charAt(0).toUpperCase() + lastname.slice(1); 
        const apiurl = `https://api-tus.whitelabel-hosting.de/api/collections/get/player?filter[firstname]=${Firstname}&filter[lastname]=${Lastname}`;
        const response = await fetch(apiurl);
        const data = await response.json();
        this.setState({ player: data.entries, loading: false });
    }

    render() {

        if(this.state.loading) {return <div className="d-flex justify-content-center"><ReactLoading className="" type="bubbles" color="#e30613" height={'5%'} width={'5%'} /></div>}

        if(!this.state.player) {return <div>no content recieved...</div>}

        return (
            <Row className="mt-4">
                <Col xs={{span: 12, order:12}} sm={{span: 5, order:1}} md={4} lg={3} xl={3}>
                <Sidebar />
                </Col>
                <Col xs={{span: 12, order: 1}} sm={{span: 7, order:12}} md={8} lg={9} xl={9}>
                    <MetaTags>
                        <title>{this.state.player[0].firstname + " " + this.state.player[0].lastname + " Spielerprofil"} | TuS Kriftel Volleyball 2. BL</title>
                        <meta name="description" content={this.state.player[0].firstname + " " + this.state.player[0].lastname + " Spielerprofil"} />
                        <meta property="og:title" content={this.state.player[0].firstname + " " + this.state.player[0].lastname + " Spielerprofil"} />
                        <meta property="og:image" content={"https://api-tus.whitelabel-hosting.de/" + this.state.player[0].image.path} />
                    </MetaTags>
                <Card className="mb-4">
                    <Row>
                        <Col xs={12} md={12} sm={12} lg={5} xl={5}>
                            <Image className="single-player-image" fluid src={"https://api-tus.whitelabel-hosting.de/" + this.state.player[0].image.path}>
                            </Image>
                        </Col>
                        <Col xs={12} md={12} sm={12} lg={7} xl={7}>
                            <div className="card-body">
                                <div className="d-flex flex-row">
                                    <h1 className="single-player-name">{this.state.player[0].firstname + " " + this.state.player[0].lastname}</h1>
                                    <div className="single-player-position">{"# " + this.state.player[0].position}</div>
                                </div>

                                <div className="single-player-membersince">{"im Verein seit " + this.state.player[0].member_since}</div>

                                    <div className="single-player-social">
                                        {this.state.player[0].instagram_url ? <a rel="noopener noreferrer" target="_blank" title="Instagram aufrufen" href={this.state.player[0].instagram_url} className="player-sm-profile-wrapper"><FontAwesomeIcon className="player-sm-profile-icon" icon={faInstagram} /></a> : ''}
                                        {this.state.player[0].facebook_url ? <a rel="noopener noreferrer" target="_blank" title="Facebook aufrufen" href={this.state.player[0].facebook_url} className="player-sm-profile-wrapper"><FontAwesomeIcon className="player-sm-profile-icon" icon={faFacebook} /></a> : ''}
                                        {this.state.player[0].linkedin_url ? <a rel="noopener noreferrer" target="_blank" title="Linkedin aufrufen" href={this.state.player[0].linkedin_url} className="player-sm-profile-wrapper"><FontAwesomeIcon className="player-sm-profile-icon" icon={faLinkedin} /></a>  : ''}
                                        {this.state.player[0].twitter_url ? <a rel="noopener noreferrer" target="_blank" title="Twitter aufrufen" href={this.state.player[0].twitter_url} className="player-sm-profile-wrapper"><FontAwesomeIcon className="player-sm-profile-icon" icon={faTwitter} /></a>  : ''}
                                    </div>

                                    <div className="single-player-details">
                                        {this.state.player[0].role ? <span>{"Position: " + this.state.player[0].role + " | "}</span> : ''}
                                        {this.state.player[0].birthday ? <span>{"Geburtsdatum: " + this.state.player[0].birthday + " | "}</span> : ''}
                                        {this.state.player[0].nationality ? <span>{"Nationalität: " + this.state.player[0].nationality + " | "}</span> : ''}
                                        {this.state.player[0].city_of_birth ? <span>{"Geburtsort: " + this.state.player[0].city_of_birth + " | "}</span> : ''}
                                        {this.state.player[0].size ? <span>{"Größe: " + this.state.player[0].size + " cm | "}</span> : ''}
                                    </div>

                                <div className="single-player-info-items mb-5">
                                    {this.state.player[0].biggest_success ? <div className="single-player-info-item">{"Größter Erfolg: " + this.state.player[0].biggest_success}</div> : ''}
                                    {this.state.player[0].if_not_volleyball ? <div className="single-player-info-item">{this.state.player[0].if_not_volleyball}</div> : ''}
                                    {this.state.player[0].life_motto ? <div className="single-player-info-item">{"Lebensmotto: " + this.state.player[0].life_motto}</div> : ''}
                                    {this.state.player[0].bio ? <div className="single-player-info-item">{"Über mich: " + this.state.player[0].bio}</div> : ''}
                                </div>

                                <button onClick={() => { this.props.history.goBack() }} type="button" className="btn btn-sm btn-primary btn-back"><FontAwesomeIcon icon={faArrowLeft} /></button>

                            </div>
                        </Col>
                    </Row>
                </Card>
                </Col>
            </Row>
        )
    }
}

export default PlayerSingle
