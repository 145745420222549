import React, { Component } from 'react'
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom'

export class CardPost extends Component {
    render() {
        return (
            <article>
                <Card className="post-card">
                    <Link className="single-post-link" to={"/posts/" + this.props.link}>
                        <Card.Img variant="top" src={this.props.img} alt={this.props.title} />
                        <Card.Body>
                            <Card.Title className="post-card-title">{this.props.title}</Card.Title>
                            <Card.Text >
                                <span className="post-card-excerpt">{this.props.excerpt}</span>
                            </Card.Text>
                        </Card.Body>
                    </Link>
                </Card>
            </article>
        )
    }
}

export default CardPost
