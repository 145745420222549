import React, { Component } from 'react'
// import SidebarCounter from './SidebarCounter'
import SidebarAktionscontainer from './SidebarAktionscontainer'
import SidebarFacebookpage from './SidebarFacebookpage'

export class Sidebar extends Component {
    render() {
        return (
            <div>
                {/* <SidebarCounter /> */}
                <SidebarAktionscontainer />
                <SidebarFacebookpage />
            </div>
        )
    }
}

export default Sidebar
