import React, { Component } from 'react'
import { Row, Col, Card, Image } from 'react-bootstrap'
import ReactLoading from 'react-loading'
import Sidebar from '../sidebar/Sidebar'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons'
import MetaTags from 'react-meta-tags'

export class Posts extends Component {

    state = {
        loading: true,
        downloads: null
    };

    async componentDidMount(){
        const apiurl = "https://api-tus.whitelabel-hosting.de/api/collections/get/downloads?sort[_id]=1&limit=100";
        const response = await fetch(apiurl);
        const data = await response.json();
        this.setState({ downloads: data.entries, loading: false });
    }
    
    render() {

        if(this.state.loading) {return <div className="d-flex justify-content-center"><ReactLoading className="" type="bubbles" color="#e30613" height={'5%'} width={'5%'} /></div>}

        // if(!this.state.downloads) {return <div>no content recieved...</div>}

        const downloads = this.state.downloads.map((download, key) =>
            <Col key={download._id} xs={12} sm={12} md={12} lg={6} xl={6} className="mb-4">
                <Card>
                    <Card.Body>
                        <Row>
                            <Col >
                                <div className="download-wrapper" xs={4}>
                                    <Image className="download-image my-auto" fluid src={"https://api-tus.whitelabel-hosting.de/" + download.image.path}></Image>
                                </div>
                            </Col>
                            <Col xs={8}>

                                <div>{download.title}</div>

                                <div className="pt-1">
                                    <div className="download-info-item mb-1">{"Dateigröße: " + download.size}</div>
                                    <div className="download-info-item">{"Dateiformat: " + download.extension}</div>
                                </div>
                                <a target="_blank" rel="noopener noreferrer" title="Download File" style={{fontSize: '13px', fontWeight: '600'}} className="mt-2 btn btn-sm btn-primary" href={"https://api-tus.whitelabel-hosting.de" + download.image.path} download>
                                    <FontAwesomeIcon style={{marginRight: '5px', color: '#fff', fontSize: '13px'}} icon={faCloudDownloadAlt} />
                                    Download
                                </a>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        );
        

        return (
            <Row className="mt-4">
                <Col xs={{span: 12, order:12}} sm={{span: 5, order:1}} md={4} lg={3} xl={3}>
                <Sidebar />
                </Col>
                <Col xs={{span: 12, order: 1}} sm={{span: 7, order:12}} md={8} lg={9} xl={9}>
                    <MetaTags>
                        <title>Downloads | TuS Kriftel Volleyball 2. BL</title>
                        <meta name="description" content="Downloads - TuS Kriftel Volleyball 2. BL" />
                        <meta property="og:title" content="Downloads - TuS Kriftel Volleyball 2. BL" />
                        <meta property="og:image" content="https://api-tus.whitelabel-hosting.de/storage/uploads/2020/02/19/5e4dc6ea91753tuskriftel-main-og.png" />
                    </MetaTags>
                    <Row>
                        {downloads}
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default Posts
