import React, { Component } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import Sidebar from '../sidebar/Sidebar'
import SweetAlert from 'sweetalert2-react';
import MetaTags from 'react-meta-tags'

export class Newsletter extends Component {

    state = {
        success: false,
        errorr: false
    }

    render() {

        return (
            <Row className="mt-4">
                <Col xs={{span: 12, order:12}} sm={{span: 5, order:1}} md={4} lg={3} xl={3}>
                <Sidebar />
                </Col>
                <Col xs={{span: 12, order: 1}} sm={{span: 7, order:12}} md={8} lg={9} xl={9} className="mb-4">
                    <MetaTags>
                        <title>Newsletter | TuS Kriftel Volleyball 2. BL</title>
                        <meta name="description" content="Newsletter der TuS Kriftel Volleyball 2. Bundesliga" />
                        <meta property="og:title" content="Newsletter der TuS Kriftel Volleyball 2. BL" />
                        <meta property="og:image" content="https://api-tus.whitelabel-hosting.de/storage/uploads/2020/02/19/5e4dc6ea91753tuskriftel-main-og.png" />
                    </MetaTags>
                    <Row>
                    <Col xs={12}> 
                            <Card clasName="card">
                                    <Card.Body>
                                        <h1 class="main-title mb-3">Newsletter Anmeldung</h1>
                                        <form>
                                            

                                            <div className="form-group">
                                                <input className="default-input" type="text" placeholder="Vorname" id="firstname-input" required></input>
                                            </div>

                                            <div className="form-group">
                                                <input className="default-input" type="text" placeholder="Nachname" id="lastname-input" required></input>
                                            </div>

                                            <div className="form-group">
                                                <input className="default-input" type="email" placeholder="E-Mail Adresse" id="email-input" required></input>
                                            </div>

                                            <div className="form-check mt-3 mb-2">
                                                <label className="checkbox-inline"><input type="checkbox" value="" checked="true" /> Ich bin mit der Verwendung meiner eingegebenen Daten zur Verarbeitung meiner Anfrage einverstanden. </label>
                                            </div>

                                        </form>

                                        
                                        <SweetAlert
                                            show={this.state.success}
                                            title="Erfolg!"
                                            text="Deine Nachricht wurde erfolreich gesendet."
                                            onConfirm={() => this.setState({ success: false })}
                                        />

                                        <SweetAlert
                                            show={this.state.error}
                                            title="Fehler!"
                                            text="Überprüfe deine Angaben und versuche es erneut."
                                            onConfirm={() => this.setState({ error: false })}
                                        />
                                        
                                        <button onClick={() => 
                                            
                                            fetch('https://api-tus.whitelabel-hosting.de/api/collections/save/newsletter', {
                                                method: 'post',
                                                headers: { 'Content-Type': 'application/json' },
                                                body: JSON.stringify({
                                                    data: {
                                                        email: document.getElementById("firstname-input").value,
                                                        firstname: document.getElementById("lastname-input").value,
                                                        lastname: document.getElementById("email-input").value
                                                    }
                                                })
                                            })
                                            .then((response) => {
                                                if(!response.ok) throw new Error(response.status);
                                                else return response.json();
                                              })
                                              .then((data) => {
                                                this.setState({ success: true });
                                                console.log("DATA STORED");
                                              })
                                              .catch((error) => {
                                                console.log('error: ' + error);
                                                this.setState({ error: true });
                                              })
                                            }id="submitButton" className="btn btn-primary btn-sm">Anmelden</button>
                                            
                                    </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default Newsletter
