import React, { Component } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import Carousel from 'react-bootstrap/Carousel'
import ReactLoading from 'react-loading'
import Sidebar from '../sidebar/Sidebar'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import MetaTags from 'react-meta-tags'

export class PostsSingle extends Component {

    state = {
        loading: true,
        post: null
    };

    async componentDidMount(){
        const apiurl = `https://api-tus.whitelabel-hosting.de/api/collections/get/mannschaften?filter[_id]=${this.props.match.params.id}`;
        const response = await fetch(apiurl);
        const data = await response.json();
        this.setState({ post: data.entries, loading: false });
    }

    render() {

        if(this.state.loading) {return <div className="d-flex justify-content-center"><ReactLoading className="" type="bubbles" color="#e30613" height={'5%'} width={'5%'} /></div>}

        if(!this.state.post) {return <div>no content recieved...</div>}

        // const images = this.state.post[0].gallery.map((image, key) =>
        // <Carousel.Item>
        //     <img
        //         className="d-block w-100"
        //         src={"https://api-tus.whitelabel-hosting.de" + image.path}
        //         alt={image.title}
        //     />
        // </Carousel.Item>
        // ); 

        return (

            <Row className="mt-4">
                <Col xs={{span: 12, order:12}} sm={{span: 5, order:1}} md={4} lg={3} xl={3}>
                <Sidebar />
                </Col>
                <Col xs={{span: 12, order: 1}} sm={{span: 7, order:12}} md={8} lg={9} xl={9}>
                    <MetaTags>
                        <title>{this.state.post[0].title} | TuS Kriftel Volleyball 2. BL</title>
                        <meta name="description" content={this.state.post[0].title} />
                        <meta property="og:title" content={this.state.post[0].title} />
                        <meta property="og:image" content={"https://api-tus.whitelabel-hosting.de/" + this.state.post[0].bild.path} />
                    </MetaTags>
                    <Row>
                        <Col xs={12}>
                            <Card style={cardStyles} className="singe-post-card">
                                <Card.Body>

                                <Carousel indicators={false}>

                                <Carousel.Item>
                                    <img
                                        className="d-block w-100"
                                        src={"https://api-tus.whitelabel-hosting.de/" + this.state.post[0].bild.path}
                                        alt={this.state.post[0].title}
                                    />
                                </Carousel.Item>

                                </Carousel>

                                <div className="single-post-title">{this.state.post[0].title}</div>

                                <div className="single-post-content"  dangerouslySetInnerHTML={{__html: this.state.post[0].inhalt}}></div>

                                <button style={{marginTop: '15px'}} onClick={() => { this.props.history.goBack() }} type="button" className="btn btn-sm btn-primary btn-back"><FontAwesomeIcon icon={faArrowLeft} /></button>
                                
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

const cardStyles = {
    marginBottom: '30px'
}

export default PostsSingle
