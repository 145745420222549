import React, { Component } from 'react'
import CardPost from '../cards/CardPost'
import { Row, Col } from 'react-bootstrap'
import ReactLoading from 'react-loading'
import Sidebar from '../sidebar/Sidebar'
import MetaTags from 'react-meta-tags'

export class Posts extends Component {

    state = {
        loading: true,
        posts: null
    };

    async componentDidMount(){
        const apiurl = "https://api-tus.whitelabel-hosting.de/api/collections/get/posts?sort[_id]=-1&limit=100&filter[thumbnail][$eq]=true";
        const response = await fetch(apiurl);
        const data = await response.json();
        this.setState({ posts: data.entries, loading: false });
    }
    
    render() {

        function trimTitle(title) {
            return title.slice(0, 45)
        }

        function trimexcerpt(excerpt) {
            return excerpt.slice(0, 170)
        }

        if(this.state.loading) {return <div className="d-flex justify-content-center"><ReactLoading className="" type="bubbles" color="#e30613" height={'5%'} width={'5%'} /></div>}

        // if(!this.state.posts) {return <div>no content recieved...</div>}

        const posts = this.state.posts.map((post, key) =>
            <Col key={post._id} xs={12} sm={12} md={12} lg={6} xl={6} className="mb-4">
                <CardPost link={post._id} title={trimTitle(post.title) + "..."} excerpt={trimexcerpt(post.excerpt) + "..."} img={post.thumbnail_url}/>
            </Col>
        );

        return (
            <Row className="mt-4">
                <Col xs={{span: 12, order:12}} sm={{span: 5, order:1}} md={4} lg={3} xl={3}>
                <Sidebar />
                </Col>
                <MetaTags>
                        <title>Aktuelle Berichte | TuS Kriftel Volleyball 2. BL</title>
                        <meta name="description" content="Aktuelle Berichte der TuS Kriftel Volleyball 2. Bundesliga" />
                        <meta property="og:title" content="Aktuelle Berichte der TuS Kriftel Volleyball 2. BL" />
                        <meta property="og:image" content="https://api-tus.whitelabel-hosting.de/storage/uploads/2020/02/19/5e4dc6ea91753tuskriftel-main-og.png" />
                </MetaTags>
                <Col xs={{span: 12, order: 1}} sm={{span: 7, order:12}} md={8} lg={9} xl={9}>
                    <Row>
                        {posts}
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default Posts
