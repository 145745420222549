import React, { Component } from 'react'
import ReactLoading from 'react-loading'
import { Row, Col, Card, Image } from 'react-bootstrap'
import Sidebar from '../sidebar/Sidebar'
import MetaTags from 'react-meta-tags'

export class Sponsors extends Component {

    state = {
        loading: true,
        content: null
    };

    async componentDidMount(){
        const apiurl = `https://api-tus.whitelabel-hosting.de/api/singletons/get/sponsoren_page`;
        const response = await fetch(apiurl);
        const data = await response.json();
        this.setState({ content: data, loading: false });
    }

    render() {

        if(this.state.loading) {return <div className="d-flex justify-content-center"><ReactLoading className="" type="bubbles" color="#e30613" height={'5%'} width={'5%'} /></div>}

        if(!this.state.content) {return <div>no content recieved...</div>}

        return (
            <Row className="mt-4">
                <Col xs={{span: 12, order:12}} sm={{span: 5, order:1}} md={4} lg={3} xl={3}>
                <Sidebar />
                </Col>
                <Col xs={{span: 12, order: 1}} sm={{span: 7, order:12}} md={8} lg={9} xl={9} className="mb-4">
                    <MetaTags>
                        <title>Sponsoren | TuS Kriftel Volleyball 2. BL</title>
                        <meta name="description" content="Sponsoren der TuS Kriftel Volleyball 2. Bundesliga" />
                        <meta property="og:title" content="Sponsoren der TuS Kriftel Volleyball 2. BL" />
                        <meta property="og:image" content="https://api-tus.whitelabel-hosting.de/storage/uploads/2020/02/19/5e4dc6ea91753tuskriftel-main-og.png" />
                    </MetaTags>
                    
                    <Row>
                        <Col xs={12}> 
                            <Card clasName="card">
                                    <Card.Body>
                                        <div className="singleton-content" dangerouslySetInnerHTML={{__html: this.state.content.content}}></div>
                                    </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={5} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e5034f75eac0kokinetics.png"></Image>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={5} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e5034f39c39bfrankfurter-volksbank.png"></Image>
                            </Card>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e5037863dd37fraport.png"></Image>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e503964ea1a2hibike.png"></Image>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e5034f8b2b0fks.png"></Image>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e5034fa486c7mainova.png"></Image>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e5034feecf2fmemo.png"></Image>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e5035056775enikolai.png"></Image>
                            </Card>
                        </Col>

                        <Col xs={12} lg={4} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e503506a3bddobstgarten.png"></Image>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e5034f213f0cdekiosk.png"></Image>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e50350a18dc2reichelt.png"></Image>
                            </Card>
                        </Col>

                        <Col xs={12} lg={4} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e503511446b6sidis.png"></Image>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e5039053f08bsparkasse1.png"></Image>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card>
                                <Image fluid src="https://api.tuskriftel-bundesliga.de/storage/uploads/2020/02/21/5e503502e14cams.png"></Image>
                            </Card>
                        </Col>
                        <Col xs={12} lg={4} className="mt-4">
                            <Card>
                                <Image fluid src="https://api-tus.whitelabel-hosting.de/storage/uploads/2023/05/30/WhatsApp-Image-2023-05-22-at-18.58.09_uid_6475e2339e7ab.jpeg"></Image>
                            </Card>
                        </Col>
                    </Row>

                </Col>
            </Row>
        )
    }
}

export default Sponsors
